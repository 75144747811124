import { render, staticRenderFns } from "./AccountNotificationSettingsSidebar.vue?vue&type=template&id=e0abfa34&scoped=true"
import script from "./AccountNotificationSettingsSidebar.vue?vue&type=script&setup=true&lang=ts"
export * from "./AccountNotificationSettingsSidebar.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./AccountNotificationSettingsSidebar.vue?vue&type=style&index=0&id=e0abfa34&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0abfa34",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VFlex,VLayout})
